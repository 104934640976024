import React, { useEffect, useState } from "react";
import TelegramLoginButton from 'react-telegram-login';

export function Login(props) {

  useEffect(() => {

  }, []);

const handleTelegramResponse = response => {
  console.log(response);
};

  return (
      <div className="WrapPageModule">
        <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="IMPULS200_bot" />
      </div>
  );
}

