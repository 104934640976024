import React, { useEffect, useState } from "react";
import Modal from "../modal/Modal";
import InputMask from 'react-input-mask';
import axios from 'axios';

 export default function ModalSendRequest(props) {

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [PromoCode, setPromoCode] = useState('');
  const [ReqPromoCode, setReqPromoCode] = useState([]);
  const [errors, setErrors] = useState([]);
  const [display, setDisplay] = useState(0);
  const [submit, setSubmit] = useState(0);
  const [managerInfo, setManagerInfo] = useState(false);

  const styleButtonSend = {
    width: '100%',
    background: 'black',
    color: 'white',
    padding: '9px 15px',
    borderRadius: '18px',
    fontFamily: "GilroyGilroyLight",
    marginRight: '22px',
    cursor: 'pointer',
    textAlign: 'center'
  };

  useEffect(() => {
    if (submit === 1 && errors.length === 0) {
      let formData = new FormData();
      formData.append('phone', phone);
      formData.append('name', name);
      formData.append('pc', PromoCode);
      formData.append('project', props.projectId);
      formData.append('mx', localStorage.getItem('manager'));

      axios.post('https://impuls200.ru/views/ajax/api/v1/srt/SendRequest.php', formData).then((REQ) => {
        if (REQ.status === 202) {
          setDisplay(1);
          setManagerInfo(REQ.data.managerInfo);
        }
      });
    }
  }, [errors, submit, PromoCode, ReqPromoCode]);

  const SendRequest = async () => {

    setErrors([]);

    if (ReqPromoCode.status === 'error') {
      setErrors((prevItems) => ["Ошибка применения промокода, ознакомьтесь с пояснением ниже.", ...prevItems]);
    }

    if (PromoCode.length > 0 && PromoCode.length !== 15) {
      setErrors((prevItems) => ["Промокод введен неверно.", ...prevItems]);
    }

    if (phone.length !== 18) {
      setErrors((prevItems) => ["Поле \"Телефон\" заполнено не корректно", ...prevItems]);
    }

    if (name.length < 3) {
      setErrors((prevItems) => ["Поле \"Имя\" содержит меньше трёх символов", ...prevItems]);
    }

    setSubmit(1);
    
  }

  useEffect(() => {
    setReqPromoCode([]);
    if (PromoCode.length === 15) {
      let formData = new FormData();
      formData.append('phone', phone);
      formData.append('pc', PromoCode);
      axios.post('https://impuls200.ru/views/ajax/api/v1/srt/CheckPromoCode.php', formData).then((REQ) => {
        setReqPromoCode(REQ.data);
      });
    } else {
      setReqPromoCode([]);
    }
  }, [PromoCode, phone])

  return (
      <Modal onClose={() => props.onClose()} NameModal="Заявка на фотосессию" show={true} footer={
        display === 0 ? (<div className="col-sm-8 col-lg-4"><div onClick={() => SendRequest()} style={styleButtonSend} className="ModalButtonFooter">Отправить заявку</div></div>) : (
          <div className="col-sm-8 col-lg-4"><div onClick={() => props.onClose()} style={styleButtonSend} className="ModalButtonFooter">Отлично</div></div>
          )
      }>
        {display === 0 ? <div className="row">

          {errors.length > 0 && <div className="error-wrap">
            {errors.map((e, k) => (
              <div key={k}>{e}</div>
            ))}
          </div>}

          {localStorage.getItem('manager') !== 'null' ?
            <div className="col-12"><div className="ManagerModalInfo">Ваша заявка будет обработана персональным менеджером.</div></div> :
            <div className="col-12"><div className="ManagerModalInfo">Ваша заявка будет обработана свободным менеджером.</div></div>
          }

          <div className="col-sm-12 col-lg-6">
              <div className="hintsInput">Телефон</div>
              <div className="form-item">
                  <InputMask autoComplete="off" onChange={e => {setPhone(e.target.value); setPromoCode('');}} type="tel" name="phone" className="form-text-input" maskChar={null} mask="+7\ (999) 999-99-99" size={20} />
              </div>
          </div>

          <div className="col-sm-12 col-lg-6">
              <div className="hintsInput">Имя</div>
              <div className="form-item">
                  <input autoComplete="off" onChange={e => setName(e.target.value)} type="text" name="name" className="form-text-input" />
              </div>
          </div>

          {phone.length === 18 && <div style={{animation: 'Animation 0.2s forwards'}} className="col-sm-12 col-lg-12">
              <div className="hintsInput">Промокод (если есть)</div>
              <div className="form-item">
                  <input autoComplete="off" onChange={e => setPromoCode(e.target.value)} type="text" name="PromoCode" className="form-text-input" />
              </div>
              {ReqPromoCode.status && <div className={'BOX-SATUS-' + ReqPromoCode.status}>{ReqPromoCode.message}</div>}
          </div>}

        </div> : 
        <div style={{animation: 'Animation 0.2s forwards'}} className="SuccesRequest">
          Спасибо! Ваша заявка успешно отправлена, наш менеджер в ближайшее время с Вами свяжется для уточнения деталей.
          {managerInfo !== false && <div style={{marginTop: '12px'}} dangerouslySetInnerHTML={{ __html: managerInfo }} className="ManagerInfoBox"></div>}
        </div>
        }
      </Modal>
  );
}
