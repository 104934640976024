import React, { useEffect, useState } from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Skeleton from 'react-loading-skeleton';
import { Rating } from 'react-simple-star-rating';
import {useParams} from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';

/**Pages**/
import ModalSendRequest from "../modal/ModalSendRequest";
import ModalSendReview from "../modal/ModalSendReview";
/****/

/**Style**/
import '../css/Project.css';
import '../css/bootstrap-grid.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
/****/


/*Icons*/
import StandardConditions_D0_30 from "../media/StandardConditions_D0_30.png";
/****/

 export function Project(props) {
  let { projectId } = useParams();
  const [data, setData] = useState([]);
  const [ActiveTab, setActiveTab] = useState("PhotoGallery");

  useEffect(() => {
    axios.post('https://impuls200.ru/views/ajax/api/v1/srt/GetInfoProject.php',  qs.stringify({id: projectId})).then(REQ => {
      setTimeout(() => { setData(REQ.data) }, 500);
  })}, []);

  const ModalOpenSendRequest = () => {
    props.setModal(<ModalSendRequest projectId={projectId} onClose={() => props.setModal()} />);
  }

  function ModalOpenSendReview() {
    props.setModal(<ModalSendReview projectId={projectId} onClose={() => props.setModal()} />);
  }

  return (
      <div className="WrapPageModule">

        {data.title  ?
        <div className="ModuleHeader-ToolBoxProject">

          <div className="ModuleHeader-WrapProjectTitle">
            <div className="ModuleHeader-ProjectTitle">{data.title}</div>
            <div className="ModuleHeader-InfoBoxProject-AdditionalButton">
              <div onClick={() => ModalOpenSendRequest()} className="ModuleHeader-InfoBoxProject-AdditionalButton-SendRequest">Отправить заявку</div>
              <div onClick={() => ModalOpenSendReview()} className="ModuleHeader-InfoBoxProject-AdditionalButton-LeaveReview">Оставить отзыв</div>
            </div>
          </div>

          <div className="ModuleHeader-ProjectRating-CityProject">
            <div className="ModuleHeader-ProjectRating">
              <div className="ModuleHeader-ProjectRating-IconStar"></div>
              {data.rating !== null ? data.rating + ' / ' + data.count_reviews + ' отзыва' : 'Ожидаем отзывы'}
            </div>

            {/*<div className="ModuleHeader-CityProject">г. Сочи</div>*/}
          </div>

          <div className="ModuleHeader-InfoBoxProject">
            <div dangerouslySetInnerHTML={{ __html: data.description }} className="ModuleHeader-InfoBoxProject-Desc"></div>
          </div>

          <div className="ModuleHeader-TabsAction">
              <div onClick={() => setActiveTab("PhotoGallery")} className={"ModuleHeader-TabItemAction "+ [ActiveTab === "PhotoGallery" ? "TabItemActionActive" : ""]}>Фотогаллерея</div>
              {/*<div onClick={() => setActiveTab("Reviews")} className={"ModuleHeader-TabItemAction " + [ActiveTab === "Reviews" ? "TabItemActionActive" : ""]}>Отзывы</div>*/}
              <div onClick={() => setActiveTab("Conditions")} className={"ModuleHeader-TabItemAction " + [ActiveTab === "Conditions" ? "TabItemActionActive" : ""]}>Условия</div>
          </div>

        </div> :
        <div>
          <Skeleton className="ModuleHeader-ProjectTitle" height="41px" width="230px"/>
          <Skeleton style={{marginBottom: '20px'}} height="41px" width="175px"/>
          <div style={{marginBottom: '20px'}}>
            <Skeleton style={{marginBottom: '7px'}} count="3" height="15px"/>
          </div>
          <div className="row">
            <Skeleton containerClassName="col-2" height="36px"/>
            <Skeleton containerClassName="col-2" height="36px"/>
          </div>
        </div>
        }

        {ActiveTab === "PhotoGallery" && data.gallery && 
          <div className="ModuleProject-PhotoGalleryWrap">
            <ResponsiveMasonry columnsCountBreakPoints={{400: 1, 750: 2, 900: 4}}>
                  <Masonry gutter="10px">
                    {data.gallery.map((ml, key_0) => {
                      return <div key={key_0} className={"ModuleProject-PhotoGallery-ItemWrap " /*+ [ml.custom_photo && "BorderReview"]*/}>
                         <div className="ModuleProject-PhotoGallery-ItemPhoto">
                           <img src={ml.link}/>
                         </div>
                         {/*ml.custom_photo && <div className="ModuleProject-PhotoGallery-CustomPhotoWrap">
                           <div className="ReviewBoxInfoCustomPhoto"><span className="ReviewTitleBox">Клиент:</span> {ml.custom_photo.client}</div>
                           <div className="ReviewBoxInfoCustomPhoto"><span className="ReviewTitleBox">Фотограф:</span> {ml.custom_photo.photographer}</div>
                           <div className="ReviewPreBox">
                            {ml.custom_photo.review}
                           </div>
                           <div className="ReviewButtonShowFullBox">Прочитать целиком</div>
                        </div>*/}
                      </div>
                    })}
                  </Masonry>
              </ResponsiveMasonry>
            </div>
        }

        {ActiveTab === "Reviews" && data.gallery && 
          <div className="ModuleProject-ReviewsWrap">
            {data.gallery.filter(ui => ui.custom_photo !== undefined).map(u => (

              <div className="ReviewItem">

                <div className="ReviewItem-ClientName-DateReview">
                  <div className="ReviewItem-ClientName">{u.custom_photo.client}</div>
                  <div className="ReviewItem-DateReview">16.10.2022</div>
                </div>

                <div className="ReviewItem-ReviewFull">{u.custom_photo.review}</div>
                <div className="ReviewItem-DetailBox">
                  <div className="ReviewItem-DetailBoxWrap">
                    <div className="ReviewItem-DetailBox-Item">Менеджер: {u.custom_photo.photographer}</div>
                    <div className="ReviewItem-DetailBox-Item">Фотограф: {u.custom_photo.manager}</div>
                  </div>
                  <div className="ReviewItem-RatingBoxWrap">
                    <Rating initialValue={u.custom_photo.rating} size="20" readonly="true" />
                  </div>
                </div>
              </div>

            ))}
          </div>
        }


        {ActiveTab === "Conditions" && data.conditions && 
          <div className="ModuleProject-Conditions">
              <div className="ModuleProject-Conditions_StandardConditions">
                <div className="ModuleProject-Conditions_StandardConditions-ITEM">
                  <div style={{backgroundImage: `url(${StandardConditions_D0_30})`}} className="ModuleProject-Conditions_StandardConditions-Icon"></div>
                  <div>Фотографии после съемки можно получить через наш специальный сервис удалённого выбора.</div>
                </div>
              </div>
              {data.conditions}
          </div>
        }
      </div>
  );
}

