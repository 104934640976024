import React from "react";
import {Link} from "react-router-dom";
import { useSearchParams, Navigate } from 'react-router-dom';

export function Header(props) {

  let [searchParams, setSearchParams] = useSearchParams();

  if (localStorage.getItem('manager') === null) {
    localStorage.setItem('manager', null);
  }

  if (searchParams.get("mx") !== null && searchParams.get("mx") !== '') {
    if (localStorage.getItem('manager') !== null) {
      if (localStorage.getItem('manager') !== searchParams.get("mx")) {
        localStorage.setItem('manager', searchParams.get("mx"));
      }
    } else {
      localStorage.setItem('manager', searchParams.get("mx"));
    }
  }

  return(
    <div className="container WrapRootPage">
      <div className="WrapHeaderModule">
        <Link className="HeaderNameCompany" to="/">
          <div>IMPULS<span>200</span></div>
        </Link>
        <div className="HeaderSocialBox">
          <div className="HeaderLogo_UserProfileIcon"></div>
          <div className="HeaderLogoWhatsApp"></div>
        </div>
      </div>
      <div className="Separator"></div>

      {props.component}

      <div class="Separator"></div>
      <div className="WrapFooterModule">
        <div className="WrapFooterModule-Сopyright">© 2022 IMPULS200</div>
        <div className="WrapFooterModule-Buttons">
          <div className="WrapFooterModule-Button">
            О нас
          </div>
          <div className="WrapFooterModule-Button">
            Команда
          </div>
          <div className="WrapFooterModule-Button">
            Вакансии
          </div>
        </div>
      </div>
    </div>
    
  );
  
}