import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import axios from 'axios';

/**Style**/
import classes from '../css/SetCity.module.css';
/****/

export function SetCity(props) {

  const [Cities, setCities] = useState([]);

  useEffect(() => {
    axios.get('https://impuls200.ru/views/ajax/api/v1/srt/GetCities.php').then(REQ => {
      setCities(REQ.data);
  })}, []);

  const ChangeCity = (id, name) => {
    localStorage.setItem('cityID', id);
    localStorage.setItem('cityName', name);
  }

  return (
    <div className={'container '+ classes.WrapRootCityPage}>
      <div className={'HeaderNameCompany ' + classes.HeaderNameCompany}>IMPULS200</div>
      <div className={classes.ChooseYourCity}>Выберите ваш город</div>
      <input autoComplete="off" type="text" placeholder="Поиск города" name="name" className={'form-text-input ' + classes.formTextInput} />

      {Cities.length > 0 &&
        <div className={classes.WrapCitiesChoose}>
          {Cities.map((ml, key_0) => {
            return <Link to="/" key={key_0} onClick={() => ChangeCity(ml.id, ml.name)} className={classes.WrapCitiesChoose_CityItem}>{ml.name}</Link>
          })}
        </div>
      }

    </div>
  );
}