import React, { useEffect, useState } from "react";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';

/**Style**/
import classes from '../css/GiftFriend.module.css';
import HeaderImage from '../media/GiftFriend/ulkmdmlwoazagrbrzpdk.png';
import Icon_R1 from '../media/GiftFriend/ycdofquftdezhjxxrozg.png';
import Icon_R2 from '../media/GiftFriend/ybftmrxmhoqifwecprgu.png';
/****/

 export function GiftFriend(props) {

  const [MyPhone, setMyPhone] = useState('');
  const [MyName, setMyName] = useState('');
  const [FriendPhone, setFriendPhone] = useState('');
  const [City, setCity] = useState('');
  const [FriendName, setFriendName] = useState('');

  const [submit, setSubmit] = useState(0);
  const [errors, setErrors] = useState([]);
  const [dataReq, setDataReq] = useState([]);

  useEffect(() => {
    if (submit === 1 && errors.length === 0) {
      let formData = new FormData();
          formData.append('phoneClient', MyPhone);
          formData.append('phoneFriend', FriendPhone);
          formData.append('nameClient', MyName);
          formData.append('nameFriend', FriendName);
          formData.append('city', City);
          formData.append('mx', localStorage.getItem('manager'));

      props.setSpinner(true);
      axios.post('https://impuls200.ru/views/ajax/api/v1/srt/InviteFriend.php', formData).then((REQ) => {
        props.setSpinner(false);
        if (REQ.status === 201) {
          setDataReq(REQ.data);
          console.log(dataReq);
        } else {
          alert("Произошла ошибка, попробуйте повторить действия позже.");
        }
      });
    }
  }, [errors, submit]);

  const SendReq = async () => {
    setErrors([]);
    setDataReq([]);

    if (MyPhone.length !== 11) {
      setErrors((prevItems) => ["Поле \"Ваш Телефон\" заполнено не корректно", ...prevItems]);
    }
    if (MyName.length < 3) {
      setErrors((prevItems) => ["Поле \"Ваше имя\" содержит меньше 3 символов", ...prevItems]);
    }
    if (FriendPhone.length !== 11) {
      setErrors((prevItems) => ["Поле \"WhatsApp друга\" заполнено не корректно", ...prevItems]);
    }
    if (FriendName.length < 3) {
      setErrors((prevItems) => ["Поле \"Имя друга\" содержит меньше 3 символов", ...prevItems]);
    }
    if (City.length < 3) {
      setErrors((prevItems) => ["Поле \"Город друга\" содержит меньше 3 символов", ...prevItems]);
    }

    setSubmit(1);

  }

  return (
      <div className="WrapPageModule">
        <div className="row">

            <div className="col-sm-12 col-lg-6">
              <div className={classes.WrapBoxSplit}>

                <img className={classes.HeaderImage} src={HeaderImage} />

                <div className={classes.InfoBoxBenefit}>
                  <div className={classes.InfoBoxBenefit__TextWrap}>
                    Мы подарим другу фотосессию и индивидуальный промокод скидку на 500₽
                  </div>
                    <img className={classes.InfoBoxBenefit__IconWrap} src={Icon_R1} />
                </div>

                <div className={classes.InfoBoxBenefit}>
                  <div className={classes.InfoBoxBenefit__TextWrap}>
                    За каждого друга, кто примет участие в фотосессии, мы начислим Вам бонусы для оплаты съёмок от нашей команды в размере 100₽.
                  </div>
                    <img className={classes.InfoBoxBenefit__IconWrap} src={Icon_R2} />
                </div>

              </div>
            </div>

            <div className="col-sm-12 col-lg-6">
              <div className={classes.WrapBoxSplit}>
                <div className={classes.TitleBoxSplit}>Порадуйте своих друзей <div>приятным подарком</div></div>
                <div className={classes.SubTitleBoxSplit}>Заполните форму приглашения ниже, и наши менеджеры лично пригласят его на фотосессию и сделают подарок от Вашего имени*</div>
                <div className={classes.WrapForm}>
                  <div className="row">

                    <div className="col-sm-12 col-lg-6">
                        <div className="form-item">
                            <PhoneInput onChange={phone => setMyPhone(phone)} disableDropdown="true" specialLabel="" placeholder="Ваш телефон" onlyCountries={['ru']} type="tel" inputClass={classes.FormInput}/>
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                        <div className="form-item">
                            <input onChange={e => setMyName(e.target.value)} placeholder="Ваше имя" autoComplete="off" type="text" name="name" className={classes.FormInput} />
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                        <div className="form-item">
                            <input onChange={e => setCity(e.target.value)} placeholder="Город друга" autoComplete="off" type="text" className={classes.FormInput} />
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                        <div className="form-item">
                            <input onChange={e => setFriendName(e.target.value)} placeholder="Имя друга" autoComplete="off" type="text" name="name" className={classes.FormInput} />
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-12">
                        <div className="form-item">
                            <PhoneInput onChange={phone => setFriendPhone(phone)} disableDropdown="true" specialLabel="" placeholder="WhatsApp друга" onlyCountries={['ru']} type="tel" inputClass={classes.FormInput}/>
                        </div>
                    </div>

                  </div>

                  <div onClick={() => SendReq()} className={classes.ButtonSend}>Пригласить друга</div>

                  {errors.length > 0 && <div className="col-sm-12 col-lg-12">
                    <div className="error-wrap">
                      {errors.map((e, k) => (
                        <div key={k}>{e}</div>
                      ))}
                    </div>
                  </div>}

                  {dataReq.BonusesInfo && <div className="col-sm-12 col-lg-12">
                      <div dangerouslySetInnerHTML={{ __html: dataReq.BonusesInfo }} className={classes.ClientBonusesInfo}></div>
                    </div>}

                  {dataReq && <div className="col-sm-12 col-lg-12">
                    <div className={classes.dataReqWrap}>
                      {dataReq.data && dataReq.data.map((e, k) => (
                        <div dangerouslySetInnerHTML={{ __html: e.ms }} className={"InfoReqBox_T" + e.type} key={k}></div>
                      ))}
                    </div>
                  </div>}

                  <div className={classes.RulesParticipation}>
                    <div>* - Приглашайте только тех людей, кто знает Вас и расположен к вам положительно;</div>
                    <div>* - Обязательно предупредите друга, что ему напишет менеджер нашей команды подарить подарок;</div>
                    <div>* - ВНИМАНИЕ! Заявки без предупреждения друзей являются спамом! что может привести к блокировке Ваших бонусов..</div>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
  );
}

