import React, { useState } from "react";
import {Route, Routes, BrowserRouter} from "react-router-dom";

/**Style**/
import './css/App.css';
import './css/bootstrap-grid.min.css';
/****/

/**Pages**/
import {Index} from "./pages/Index";
import {Project} from "./pages/Project";
import {GiftFriend} from "./pages/GiftFriend";
import {SetCity} from "./pages/SetCity";
import {Login} from "./pages/Login";
import {Header} from "./pages/Header";
/****/

function App() {

  const [modals, setModal] = useState([]);
  const [spinner, setSpinner] = useState(false);

  return (
    <div>
      <BrowserRouter>
          <Routes>
              <Route index path="/" element={<Header component={<Index />}></Header>} />
              <Route path="SetCity/" element={<SetCity/>} />
              <Route path="GiftFriend/" element={<Header  component={<GiftFriend setSpinner={setSpinner} />}></Header>} />
              <Route path="Login/" element={<Header component={<Login />}></Header>} />
              <Route path="projects/:projectId" element={<Header component={<Project setModal={setModal} />}></Header>} />
          </Routes>
      </BrowserRouter>
      <div className="package-modal">{modals}</div>

      <div className={"UIWrapperSpinner " + (spinner == false && "SpinnerHidden")} aria-hidden="true" style={{background: 'rgb(239 239 239 / 44%)'}}>
        <div className="UIWrapperSpinnerBox">
          <svg className="UIIconSVGSpinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="spinner_44">
            <path d="M22 44a1.5 1.5 0 010-3c10.493 0 19-8.507 19-19S32.493 3 22 3 3 11.507 3 22c0 2.208.376 4.363 1.103 6.397a1.5 1.5 0 11-2.825 1.01A21.964 21.964 0 010 22C0 9.85 9.85 0 22 0s22 9.85 22 22-9.85 22-22 22z" fill="currentColor" fillRule="nonzero"></path>
          </svg>
        </div>
      </div>

    </div>
  );
}

export default App;
