import React, { useEffect, useState } from "react";

/***Style***/
import '../css/Modal.css';
/***********/

 export default function Modal(props) {

  return (props.show == true && <div className="modal-cart modal-visible">
        <div className="modal-overlay">
            <div className="modal_c col-sm-5 col-lg-4">

                <div className="modal_wrap">
                    
                    <div className="modal_header">
                        <div className="modal-header-title">{props.NameModal}</div>
                        <button onClick={() => props.onClose()} type="button" className="modal_cart_close"></button>
                    </div>

                    <div className="modal_content">
                      {props.children}
                    </div>

                    {props.footer && <div className="modal_footer">
                      {props.footer}
                    </div>}

                </div>

            </div>
        </div>
    </div>
  );
}

