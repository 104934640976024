import React, { useEffect, useState } from "react";
import Modal from "../modal/Modal";
import InputMask from 'react-input-mask';
import { Rating } from 'react-simple-star-rating';
import axios from 'axios';

export default function ModalSendReview(props) {

    const [ratePhotographerWork, setRatePhotographerWork] = useState(0);
    const [rateManagerWork, setRateManagerWork] = useState(0);
    const [rateServices, setRateServices] = useState(0);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [reviewText, setReviewText] = useState('');

    const [errors, setErrors] = useState([]);
    const [display, setDisplay] = useState(0);
    const [submit, setSubmit] = useState(0);

      const styleButtonSend = {
        width: '100%',
        background: 'black',
        color: 'white',
        padding: '9px 15px',
        borderRadius: '18px',
        fontFamily: "GilroyGilroyLight",
        marginRight: '22px',
        cursor: 'pointer',
        textAlign: 'center'
      };

    useEffect(() => {
        if (submit === 1 && errors.length === 0) {
          let formData = new FormData();
            formData.append('ratePhotographerWork', ratePhotographerWork);
            formData.append('rateManagerWork', rateManagerWork);
            formData.append('rateServices', rateServices);
            formData.append('phone', phone);
            formData.append('name', name);
            formData.append('reviewText', reviewText);
            formData.append('project', props.projectId);
            formData.append('mx', localStorage.getItem('manager'));

          axios.post('https://impuls200.ru/views/ajax/api/v1/srt/SendReview.php', formData).then((REQ) => {
            if (REQ.status === 202) {
              setDisplay(1);
            } else if(REQ.status === 203) {
                alert("К сожалению нам не удалось идентифицировать Вас как клиента этого проекта");
            }
          });
        }
    }, [errors, submit]);

    const SendRequest = async () => {

        setErrors([]);

        if (ratePhotographerWork < 1) {
          setErrors((prevItems) => ["Необходимо оценить работу фотографа", ...prevItems]);
        }

        if (rateManagerWork < 1) {
          setErrors((prevItems) => ["Необходимо оценить работу менеджера", ...prevItems]);
        }

        if (rateServices < 1) {
          setErrors((prevItems) => ["Необходимо оценить работу сервисов", ...prevItems]);
        }

        if (phone.length !== 18) {
          setErrors((prevItems) => ["Поле \"Телефон\" заполнено не корректно", ...prevItems]);
        }

        if (name.length < 3) {
          setErrors((prevItems) => ["Поле \"Имя\" содержит меньше 3 символов", ...prevItems]);
        }

        if (reviewText.length < 5) {
          setErrors((prevItems) => ["Поле \"Отзыв\" содержит меньше 5 символов", ...prevItems]);
        }

        setSubmit(1);
        
    }


  return (
    <Modal onClose={() => props.onClose()} NameModal="Оставить отзыв" show={true} footer={
        display === 0 ? (<div className="col-sm-8 col-lg-4"><div onClick={() => SendRequest()} style={styleButtonSend} className="ModalButtonFooter">Отправить отзыв</div></div>) : (
          <div className="col-sm-8 col-lg-4"><div onClick={() => props.onClose()} style={styleButtonSend} className="ModalButtonFooter">Отлично</div></div>
          )
      }>

      {display === 0 ? <div className="row">

        {errors.length > 0 && <div className="error-wrap">
            {errors.map((e, k) => (
              <div key={k}>{e}</div>
            ))}
        </div>}

        <div className="col-sm-12 col-lg-6">
          <div className="RatingFormModal">
            <div className="hintsInput">Оцените работу фотографа</div>
            <div className="form-item">
               <Rating onClick={(e) => setRatePhotographerWork(e)} size="26" />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg-6">
          <div className="RatingFormModal">
            <div className="hintsInput">Оцените работу менеджера</div>
            <div className="form-item">
               <Rating onClick={(e) => setRateManagerWork(e)} size="26" />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg-12">
          <div className="RatingFormModal">
            <div className="hintsInput">Оцените удобство использование сервисов</div>
            <div className="form-item">
               <Rating onClick={(e) => setRateServices(e)} size="26" />
            </div>
          </div>
        </div>

        <div className="col-sm-5 col-lg-6">
            <div className="hintsInput">Телефон</div>
            <div className="form-item">
                <InputMask onChange={e => setPhone(e.target.value)} name="PhoneTextBoxContactForm" type="tel" className="form-text-input" maskChar={null} mask="+7\ (999) 999-99-99" size={20} />
            </div>
        </div>

        <div className="col-sm-5 col-lg-6">
            <div className="hintsInput">Имя</div>
            <div className="form-item">
                <input onChange={e => setName(e.target.value)} type="text" name="name" className="form-text-input"/>
            </div>
        </div>

        <div className="col-sm-12 col-lg-12">
            <div className="hintsInput">Отзыв</div>
            <div className="form-item">
                <textarea onChange={e => setReviewText(e.target.value)} style={{height: "100px"}}  name="comment" className="form-text-input"></textarea>
            </div>
        </div>

      </div> : <div className="SuccesRequest">Спасибо что делаете нас лучше! Ваш отзыв передан на рассмотрение, мы стараемся делать нашу работу максимально качественно, если Вас что-то не устроило, мы обязательно выясним детали и свяжемся с Вами.</div>}
    </Modal>
  );
}
