import React, {useEffect} from "react";
import { Navigate } from 'react-router-dom';

/**Pages**/
import OurProjects from "../pages/Box/OurProjects/Main";
/****/

export function Index(props) {

  if (localStorage.getItem('cityID') === null) {
    return <Navigate to="/SetCity" replace={true} />;
  }

  return(
    <div className={"WrapPageModule"}>
      <div className="WrapIndexPage-Slider"><img src="https://impuls.hb.bizmrg.com/static/ed2f1577c170a47969c62322f253d36d.png"/></div>
      <OurProjects/>
    </div>
  )

}

export default Index;