import React from "react";
import axios from 'axios';
import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

/**Style**/
import './Main.css';
import 'react-loading-skeleton/dist/skeleton.css';
/****/

/*Icons*/
import GeoMarker64 from "../../../media/GeoMarker-64.png";
/****/

class OurProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      DataView: [],
      ManagerInfo: false
    }

    this.Init();

  }

  async Init() {
    let formData = new FormData();
    formData.append('cityID', localStorage.getItem('cityID'));
    formData.append('mx', localStorage.getItem('manager'));
    await axios.post('https://impuls200.ru/views/ajax/api/v1/srt/GetProjects.php', formData).then(REQ => {
      setTimeout(() => { this.setState({DataView: REQ.data}); this.setState({ManagerInfo: REQ.data.managerInfo}); }, 1000);
    });
  }

  truncate(str) {
    return str.length > 155 ? str.substring(0, 155) + "..." : str;
  }

  render() {
    return(
      <div className="WrapPageModule-OurProjects">

          {this.state.ManagerInfo !== false && <div style={{marginBottom: '26px'}} dangerouslySetInnerHTML={{ __html: this.state.ManagerInfo }} className="ManagerInfoBox"></div>}

          <div className="ModuleTitle-OurProjects-Wrap">
            <div className="ModuleTitle-OurProjects">Наши текущие проекты</div>
            <div className="ModuleTitle-OurProjects-CityBox">
              <div style={{backgroundImage: `url(${GeoMarker64})`}} className="ModuleTitle-OurProjects_CityBox-Icon"></div>
              <Link to="/SetCity" className="ModuleTitle-OurProjects_CityBox-Name">{localStorage.getItem('cityName')}</Link>
            </div>
          </div>

          {/*<div className="OurProjects-Tags-Wrap">
            <div className="OurProjects-Tags-Item OurProjects-Tags-ItemActive">Все проекты</div>
            <div className="OurProjects-Tags-Item">Семейные</div>
            <div className="OurProjects-Tags-Item">Детские</div>
            <div className="OurProjects-Tags-Item">Для парней</div>
            <div className="OurProjects-Tags-Item">Для девушек</div>
            <div className="OurProjects-Tags-Item">Лес, парки</div>
          </div>*/}

          <div className="OurProjects-WrapDisplay">
            <div className="row">
              {this.state.DataView.projects ?
                this.state.DataView.projects.length > 0 ? (
                  this.state.DataView.projects.map((f, index) => (
                    <div key={index} className="col-12 col-lg-3">
                      <div style={{backgroundImage: `url(${f.cover})`}} className="OurProjects-DataCard">
                        <div className="OurProjects-DataCard-MainWrap">
                          <div className="OurProjects-DataCard-WrapRating">
                            <div className="OurProjects-DataCard-Rating">
                            <div className="OurProjects-DataCard-Rating-IconStar"></div>
                              {f.rating}
                            </div>
                          </div>
                          <div className="OurProjects-DataCard-WrapName">
                            <div className="OurProjects-DataCard-Name">{f.name}</div>
                          </div>
                          <div className="OurProjects-DataCard-DetailData">
                            <Link className="OurProjects-DataCard-DetailData-ButtonMore" to={"/projects/" + f.id}>Подробнее</Link>
                            <div className="OurProjects-DataCard-DetailData-text">{this.truncate(f.description)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  localStorage.getItem('manager').length > 4 ? (<div className="NotFoundProjectsAlert">Проектов у текущего менеджера в данном городе пока не запущено, но скоро мы всё исправим.</div>) :
                  (<div className="NotFoundProjectsAlert">Проектов в данном городе пока не запущено, но скоро мы всё исправим.</div>)
                )
                : (<div className="row">
                    <Skeleton containerClassName="col-12 col-lg-3" height="392px"/>
                    <Skeleton containerClassName="col-12 col-lg-3" height="392px"/>
                    <Skeleton containerClassName="col-12 col-lg-3" height="392px"/>
                    <Skeleton containerClassName="col-12 col-lg-3" height="392px"/>
                  </div>)
              }

            </div>          
          </div>
      </div>
    );
  }
}

export default OurProjects;